import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { PageBase, RentalInfo, RentalStatus } from 't4core';
import { AppIntegrationService, AppSettingsService, FindLockResult } from 't4-app-integration';
import { NavigationResolver, NavigationService, ActiveRentalService, LayoutService } from 'app-components';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-nfc-nolock',
  templateUrl: './nfc-nolock.component.html',
  styleUrls: ['./nfc-nolock.component.css']
})
export class NFCNoLockComponent extends PageBase implements OnInit, OnDestroy {

  public count = 0;
  public unlocked = false;

  public booking: RentalInfo;
  public status = RentalStatus;
  public firstLoad: boolean = true;
  public objectId: number;
  public unlockingInfo: string;
  public alternativesLoaded: boolean = false;
  public isAndroid: boolean = false;
  public isBauhaus: boolean = false;

  public event: string = ""; // Pickup/Return/Active etc.


  public device: string = null;

  // States
  public isWaiting: boolean = true;
  public isSearching: boolean = false;
  public hasFailed: boolean = false;
  public hasCompleted: boolean = false;

  public failReason: number;

  private timerSubscription: Subscription;
  private nfcSubscription: Subscription;

  private completeCount: number = 0;

  constructor(el: ElementRef, public appSvc: AppIntegrationService, private aviationService: NavigationService, private rentalService: ActiveRentalService, public layoutService: LayoutService, public resolver: NavigationResolver, public appSettings: AppSettingsService, private navigator: NavigationService) {
    super(el);

    var gotoStep = this.navigator.getParam("step");
    if (gotoStep == "IsComplete") {
      this.isWaiting = false;
      this.isSearching = false;
      this.hasCompleted = true;
    }

    this.appSvc.requestDeviceState();

    this.device = this.getMobileOperatingSystem();

    this.timerSubscription = this.appSvc.getSecondTimer().subscribe(x => {
      // Update search timer
      if (this.isSearching) {
        if (this.count < 30) {
          this.count++;
        } else {
          this.fail(2);
        }
      }

      // Redirect when completed for 10 seconds
      if (this.hasCompleted) {
        if (this.completeCount > 10) this.next();
        else this.completeCount++;
      }
    });
  }

  async ngOnInit() {
    // If the current object does not have a tag, continue immediately.
    if (!await this.rentalService.hasTag()) {
      this.ngOnDestroy();

      this.rentalService.fetchSecurity(false);
      this.aviationService.executeCommand("Next");
      return;
    }

    this.booking = await this.rentalService.getBooking();
    this.Api.get<boolean>("/Rental/SetRentalEvent", { rentalId: this.booking.Id, content: "Begin Nfc unlock!" });

    this.objectId = this.booking.ObjectId;

    // Register listeners
    this.nfcSubscription = this.appSvc.nfcDetected.subscribe(tag => {
      var devices = this.booking.RentalObject.Locks;
      var info = "Found Nfc tag: " + tag;


      var parts = tag.split('://');
      if (parts.length != 2) {
        this.Insights.logEvent("Invalid tag: " + tag);
        this.fail(1);
      }
      var app = parts[0];

      parts = parts[1].split('#');
      var regno = parts[0];
      var action = parts.length > 1 ? parts[1] : '';

      // Handle result
      if (tag && devices.some(x => x.Id == regno)) {
        this.Insights.logEvent(info);
        if (this.booking) this.Api.get<boolean>("/Rental/SetRentalEvent", { rentalId: this.booking.Id, content: info });

        // Continue process (Twice if we officially havent started looking yet)
        if (this.isWaiting) this.next();
        this.next();


      } else if (tag) {
        info += "; Wrong tag";
        this.Insights.logEvent(info);
        if (this.booking) this.Api.get<boolean>("/Rental/SetRentalEvent", { rentalId: this.booking.Id, content: info });
        this.fail(1);
      } else {
        info = "No tag found";
        this.Insights.logEvent(info);
        if (this.booking) this.Api.get<boolean>("/Rental/SetRentalEvent", { rentalId: this.booking.Id, content: info });
        this.fail(2);
      }
    });
  }

  ngOnDestroy() {
    if (this.nfcSubscription) this.nfcSubscription.unsubscribe();
    if (this.timerSubscription) this.timerSubscription.unsubscribe();
  }

  public next() {
    this.count = 0;
    if (this.isWaiting) {
      this.isWaiting = false;
      this.isSearching = true;
    } else if (this.isSearching) {
      this.isSearching = false;
      this.hasCompleted = true;
    } else if (this.hasCompleted) {
      this.ngOnDestroy();

      this.rentalService.fetchSecurity(false);
      this.aviationService.executeCommand("Next");
    }
  }

  private fail(reason?: number) {
    if (this.hasFailed) return;
    this.hasFailed = true;
    this.isSearching = false;
    this.failReason = reason;
  }

  private retryCount: number = 0;
  public retry() {
    this.retryCount++;

    this.count = 0;
    if (this.hasFailed) {
      this.hasFailed = false;
      this.failReason = null;
      this.isWaiting = true;
      this.beginSearch();
    }
  }



  public beginSearch() {
    this.next();
    this.count = 0;

    this.Insights.logEvent("Looking for Nfc tag");

    // Ask the device to start searching
    this.appSvc.startNfcSearch();
  }

  public getMobileOperatingSystem() : string {
    var userAgent = navigator.userAgent || navigator.vendor;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    var win: any = window;
    if (/iPad|iPhone|iPod/.test(userAgent) && !win.MSStream) {
      return "iOS";
  }

  return "unknown";
}
}
