import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { BookingErrorCode, BookingValidation, ComponentBase, RentalInfo, RentalStatus } from 't4core';
import * as moment from 'moment';
import { AppIntegrationService, Coordinates, AppSettingsService } from 't4-app-integration';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-rental-box',
  templateUrl: './rental-box.component.html',
  styleUrls: ['./rental-box.component.css']
})
export class RentalBoxComponent extends ComponentBase implements OnInit, OnDestroy {
  @ViewChild('gmap', { static: false }) gmapElement: any;

  private map: google.maps.Map;
  private zoomDistances = [
    { zoom: 17, maxDistance: 0.2 },
    { zoom: 15, maxDistance: 0.6 },
    { zoom: 14, maxDistance: 2 },
    { zoom: 12, maxDistance: 4 },
    { zoom: 11, maxDistance: 8 },
    { zoom: 9, maxDistance: 16 },
    { zoom: 8, maxDistance: 32 },
    { zoom: 7, maxDistance: 64 },
    { zoom: 6, maxDistance: 128 },
    { zoom: 5, maxDistance: 256 },
    { zoom: 4, maxDistance: 512 },
    { zoom: 3, maxDistance: 1024 },
    { zoom: 2, maxDistance: 2048 },
    { zoom: 1, maxDistance: 4096 }
  ];


  @Input() public booking: RentalInfo;
  @Input() public hasLock: boolean = true;
  @Input() public allowUnmanned: boolean = true;


  @Output() public rentalAction = new EventEmitter<string>();
  public userLocation: Coordinates = { Latitude: 0, Longitude: 0 };
  public toEarly: boolean = false;
  public toFarAway: boolean = false;
  public late: boolean = false;
  public isVarebil: boolean = false;
  public isReturn: boolean = false;
  public firstLoad = true;
  public updateInProgress = false;
  public updateInProgressWait = false;
  public locationTries: number = 0;
  public pCountdown: moment.Duration;
  public rCountdown: moment.Duration;
  public available: boolean = false; 
  public distance: number = 0;
  public deviceState: number;
  private locationSubscription;//: Subscription; // current assignment is mysteriously missing a property.
  private timerSubscription: Subscription;

  listener = () => {
    if (!document.hidden) {
      this.updateInProgress = true;
      this.update().then(() =>
        this.updateInProgress = false
      );
    }
  }

  constructor(public appService: AppIntegrationService, private appSettings: AppSettingsService) {
    super();

    }

  
  async ngOnInit() {
    this.appService.requestDeviceState();

    if (this.appSettings.meetsMinimumAppVersion(3.1)) {
      await this.appService.ensureLocation();
    } else {
      this.appService.Device.Location = 3;
    }

    if (this.appService.Device.Location < 3) {
      this.Api.get<boolean>("/Rental/SetRentalEvent", { rentalId: this.booking.Id, content: "Access to location denied" });
    }

    this.timerSubscription = this.appService.getSecondTimer().subscribe(x => {
      if (this.appService.Device.Location < 3) this.appService.requestDeviceState();
    });

    this.appService.setLocationUpdateInterval(15000);
    this.locationSubscription = this.appService.locationChanged.subscribe(() => this.callUpdate());

    this.late = this.booking.Status == RentalStatus.Late;

    //Temporarly Solution for Bauhaus Varebil.   As we should not be able to extend them.
    const varebilPrimaryObjectTypes = [657, 873, 874, 876, 933, 950, 415, 1088];
    this.isVarebil = varebilPrimaryObjectTypes.includes(this.booking.PrimaryObjectTypeId);

    //Update GPS location when user opens app
    document.addEventListener("visibilitychange", this.listener, true);
    //Remove * from reg number if it exists
    if (this.booking && this.booking.RentalObject.Title.indexOf('*') == (this.booking.RentalObject.Title.length - 1)) {
      this.booking.RentalObject.Title = this.booking.RentalObject.Title.slice(0, this.booking.RentalObject.Title.indexOf('*'));
    }

    this.isReturn = (this.booking && this.booking.Status == RentalStatus.Active || this.booking.Status == RentalStatus.Late);

    this.secondTick();
    this.minuteTick();

    await this.Isavailable();

    await this.update(true);

  }

  async ngOnDestroy() {
    this.appService.setLocationUpdateInterval(null);
    if (this.locationSubscription)
      this.locationSubscription.unsubscribe();
    this.timerSubscription.unsubscribe();
  }

  public async initMap() {
    if (this.map) return;

    var distance = await this.appService.getDistanceFromUser(this.booking.PickupLocation.TrailerPointLatitude, this.booking.PickupLocation.TrailerPointLongitude);
    var mapProp = {
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      key: "AIzaSyBEjGnurCxh3ZDYNjyxQDlaCZXQxxvM0-E",
      disableDefaultUI: true,
      mapId: '17c55b6aa3c322c5',
      zoom: this.zoomDistances.find(x => x.maxDistance > distance).zoom,
      center: { lat: this.booking.PickupLocation.TrailerPointLatitude, lng: this.booking.PickupLocation.TrailerPointLongitude },
      
    };
  
    if (this.gmapElement && this.gmapElement.nativeElement) {
      this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
    }
    this.placeTrailerPoint(true);
  }

 

  private pin: google.maps.Marker;
  private marker: google.maps.Marker;
  private circle: google.maps.Circle;
  private async placeTrailerPoint(firstLoad: boolean = false) {
    if (!this.map) return;

    if (this.pin) {
      this.pin.setMap(null);
    }

    var coors = await this.appService.getCoordinates();
    if (!coors || this._isDestroyed) return;

    this.pin = new google.maps.Marker({
      position: { lat: coors.Latitude, lng: coors.Longitude },
      draggable: false,
      map: this.map,
    });

    if (!this.marker) {
      this.marker = new google.maps.Marker({
        position: { lat: this.booking.PickupLocation.TrailerPointLatitude, lng: this.booking.PickupLocation.TrailerPointLongitude },
        draggable: false,
        map: this.map,
      });
      const theme = this.appSettings.settings.theme || 'Default';
      this.marker.setIcon(`/Styles/${theme}/Pin.png`);

      var circle = new google.maps.Circle({
        strokeColor: "#ADD8E6",
        strokeOpacity: 0.8,
        fillColor: "#32CD32",
        strokeWeight: 1,
        map: this.map,
        fillOpacity: 0.35,
        radius: this.booking.PickupLocation.TrailerPointRadius
      })


      this.circle = circle;
      this.circle.bindTo('center', this.marker, 'position');
    }

    if (firstLoad) {
      var distance = await this.appService.getDistanceFromUser(this.booking.PickupLocation.TrailerPointLatitude, this.booking.PickupLocation.TrailerPointLongitude);
      this.map.setZoom(this.zoomDistances.find(x => x.maxDistance > distance).zoom);

      setTimeout(() => this.map.panTo({ lat: (this.booking.PickupLocation.TrailerPointLatitude + coors.Latitude) / 2, lng: (this.booking.PickupLocation.TrailerPointLongitude + coors.Longitude) / 2 }), 100);
    }
  }


  public async callUpdate() {
    if (this.updateInProgressWait) return;

    this.updateInProgressWait = true;
    await this.update();
    this.updateInProgressWait = false;
  }

  private oldToFarAway:boolean = this.toFarAway;
  public async update(firstLoad = false) {
    //Fetch current current location
    this.updateInProgress = true;
    try {
      this.userLocation = await this.appService.getCoordinates();
      if (this._isDestroyed) return;

      if (this.userLocation == null) {
        //await this.UI.alert("Intellitrailer.Rental.BookingInfo.LocationNotFoundTtl", "Location not found", "Intellitrailer.Rental.BookingInfo.LocationNotFoundMsg", "The app could not fetch your location and will retry again.");
        this.Insights.logEvent("RentalBoxNoLocation", { IsReturn: this.isReturn.toString()})
        //this.update(firstLoad);
        return;
      }
    }
    catch (ex) {
      //await this.UI.alert("Intellitrailer.Rental.BookingInfo.LocationNotFoundTtl", "Location not found", "Intellitrailer.Rental.BookingInfo.LocationNotFoundMsg", "The app could not fetch your location and will retry again.");
      //this.update(firstLoad);
      return
    }

    //Check if user is to far away to start/return trailer
    this.distance = this.appService.getDistance({ Latitude: this.booking.PickupLocation.TrailerPointLatitude, Longitude: this.booking.PickupLocation.TrailerPointLongitude }, this.userLocation) * 1000;
    this.toFarAway = this.distance > this.booking.PickupLocation.TrailerPointRadius + 1000;
    console.log(this.toFarAway);
    console.log(this.distance);

    //Override for demo (Set user as at location)
    if (localStorage.getItem('atLocation')) {
      this.toFarAway = localStorage.getItem('atLocation') == "true" ? false : this.toFarAway;
    }

    //Set insights
    if (this.toFarAway != this.oldToFarAway) {
      if (this.toFarAway) {
        setTimeout(() => this.initMap(), 100);
        this.Api.get<boolean>("/Rental/SetRentalEvent", { rentalId: this.booking.Id, content: "Left trailer point: " + Math.round(this.distance) + "m, " + JSON.stringify(this.userLocation) });
      }
      else this.Api.get<boolean>("/Rental/SetRentalEvent", { rentalId: this.booking.Id, content: "Entered trailer point: " + Math.round(this.distance) + "m" });

      this.oldToFarAway = this.toFarAway;
    }

    this.placeTrailerPoint();
    this.updateInProgress = false;
  }

  public async pickupNow() {
    var ret = await this.UI.confirm("Intellitrailer.Rental.RentalBox.headConfirmEarlyPickup", "Confirm early pickup", "Intellitrailer.Rental.RentalBox.msgConfirmEarlyPickup", "You are to early to pickup this object. If you start the rental now the end time will be moved to maintain the length of the booking. Continue?");
    if (ret) {
      var length = this.booking.ReturnTime.diff(this.booking.PickupTime, 'minutes', true);

      var newStart = moment();
      var newEnd = moment().add(length, 'minutes');

      var validation = await this.Api.put<BookingValidation>("/Rental/ChangeBooking", null,  {
        rentalId: this.booking.Id,
        pickupTime: newStart.toISOString(),
        returnTime: newEnd.toISOString(),
        pickupLocation: this.booking.PickupLocationId,
        returnLocation: this.booking.ReturnLocationId,
        typeId: this.booking.PrimaryObjectTypeId
      });

      if (validation.AggregatedResult == BookingErrorCode.Valid) {
        this.sendRentalAction('start');
      }
    }
  }

  public async Isavailable() {

    this.available = await this.Api.get<boolean>("/Rental/IsAvailable", { rentalId: this.booking.Id }); 
  } 

  async secondTick() {
    if (this.booking && this.booking.Id > 0) {
      this.pCountdown = moment.duration(this.booking.PickupTime.diff(moment()));
      this.rCountdown = moment.duration(this.booking.ReturnTime.diff(moment()));
      this.toEarly = this.pCountdown.asSeconds() > this.Settings.clientSettings.Bookings.EarlyPickupInMinutes * 60;
      this.late = this.rCountdown.asSeconds() < 0;
    }
  }

  public sendRentalAction(action: string) {
    this.rentalAction.emit(action);
  }

  public retry() {
    this.deviceState = this.appService.Device.Location;
  }
  public openSettings() {
    this.Api.get<boolean>("/Rental/SetRentalEvent", { rentalId: this.booking.Id, content: "Opened device settings to allow location services " });
    this.appService.openPermissions();
  }
}
