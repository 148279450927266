import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationCancel, NavigationEnd, NavigationStart, Route, Router, RouterStateSnapshot, Routes } from '@angular/router';
import * as moment from 'moment';
import { filter } from 'rxjs/operators';
import { ApiService, AuthService, InsightsService, RentalInfo, SettingsService, TranslationService, UIService } from 't4core';
import * as uuid from 'uuid';
import { ApplicationContext, NavigationStep, NavType } from '../../Models/NavigationStep';
import { AppIntegrationService, AppSettingsService } from 't4-app-integration';
import { LayoutService } from '../LayoutService/layout.service';
import { BookingService } from '../BookingService/booking-service.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationResolver  {
  constructor(
    private aviationService: NavigationService,
    private authService: AuthService,
    private UI: UIService,
    private Api: ApiService,
    private appService: AppIntegrationService,
    private translations: TranslationService,
    private appSettings: AppSettingsService,
    private settings: SettingsService,
    private insights: InsightsService) { }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<string> {
    try {
      var ls = JSON.stringify(window.localStorage);
      this.insights.logEvent("Local storage: " + ls);
    } catch (ex) {
      this.insights.logError(ex);
    }

    while (!this.aviationService.activeStep || route.url.toString().indexOf("ResetView") >= 0) {
      return;
    }
    var newContext: ApplicationContext = ApplicationContext.Owner;
    if (this.aviationService.activeStep.Context) newContext = this.aviationService.activeStep.Context;

    if (this.aviationService._activeContext != newContext || this.aviationService.isInitialStep) {
      this.aviationService.isInitialStep = false;
      //var token = this.UI.beginLoading("Intellitrailer.App.Navigation.Loading", "Preparing view", null);

      //Is the the new view a customer context?
      if (newContext == ApplicationContext.Customer) {
        //Log in as existing user if user is logged in
        if (this.appSettings.getUserId()) {
          var password = this.appSettings.getUserId();
          await this.authService.Authenticate("", this.appSettings.getUserId(), password);
        }
        //Go home if not logged in
        else {
          try {
            var ls = JSON.stringify(window.localStorage);
            this.insights.logEvent("Local storage2: " + ls);
          } catch (ex) {
            this.insights.logError(ex);
          }

          this.appSettings.dumpUser();
          await this.authService.Authenticate("", this.appSettings.contextId, this.appSettings.privateKey, this.appSettings.getUserId());
          this.aviationService.menuNavigate("Start", 'A');
        }
      }
      //Is the the new view a region context?
      else if (newContext == ApplicationContext.Region && this.settings.clientSettings.RentalLocationId == null) {
        var region = await this.getClosestParent(this.appSettings.localLocationId);
        if (region) {
          await this.authService.Authenticate("", region.Item1, region.Item2, this.appSettings.getUserId());
        }
        else {
          await this.authService.Authenticate("", this.appSettings.contextId, this.appSettings.privateKey, this.appSettings.getUserId());
        }
      }
      //If the new view is a owner context authenticate as API user
      else {
        await this.authService.Authenticate("", this.appSettings.contextId, this.appSettings.privateKey, this.appSettings.getUserId());
        console.debug("Not user context");
      }

      //if (localStorage.getItem('language')) {
      //await this.translations.changeLanguage(localStorage.getItem('language'));
      //}

      this.aviationService._activeContext = newContext;

      this.translations.load();
      //this.UI.loaderCompleted(token);
    }
    return "";
  }

  private regionalContexts: any[] = [];
  public async getClosestParent(locationId: number): Promise<any> {
    if (!locationId) return null;

    var ret = this.regionalContexts.find(x => x.LocationId == locationId);
    if (ret) return ret.Data;

    var closestParent = await this.Api.get<any>("Account/FindClosestParent", { locationId: locationId, parentId: this.appSettings.contextId });
    this.regionalContexts.push({ LocationId: locationId, Data: closestParent });

    return closestParent;
  }
}

export interface jsonStep {
  Key: any;
  Path: any;
  Component: any;
  Params?: any | undefined;
  Actions: any[];
  Context?: any;

  AllowColdStart?: Boolean;
}

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private static Steps: NavigationStep[] = [];
  public firstTimeShowActiveBookings = true;
  public isInitialStep: boolean = true;
  public resolverDoneLoading: boolean = false;
  public isGoingBack: boolean = false;

  public static getRoutes(steps: NavigationStep[]): Routes {
    this.Steps = steps;
    let routes = steps.map<Route>(x => ({ path: x.Path, component: x.Component, data: { animation: x.Key }, resolve: { init: NavigationResolver } }));
    return routes;
  }

  public async setRoutes(): Promise<Routes> {
    let theme: string = this.appSettings.settings.overrideRoutes ? this.appSettings.settings.theme : 'Default';
    let path: string = `/Styles/${theme}/Routes/${this.appSettings.settings.typeSettings.appType}.json?v=` + this.appSettings.clientVersion;

    NavigationService.Steps = await this.http.get<NavigationStep[]>(path).toPromise();
    return NavigationService.Steps.map<Route>(step => ({
      path: step.Path,
      data: { animation: step.Key },
      resolve: { init: NavigationResolver },
      component: AppSettingsService.components[step.Component.toString()]
    }));
  }

  public _activeContext: ApplicationContext = ApplicationContext.Owner;
  public _isInitialized: boolean = false;
  private routes: Routes;

  private _activeStep: NavigationStep;
  public get activeStep(): NavigationStep { return this._activeStep; }
  private setActiveStep(step: NavigationStep) {
    this._activeStep = step;
  }
  private history: NavigationStep[] = [];

  public settingsParameter: string;

  constructor(
    public router: Router,
    private location: Location,
    private appService: AppIntegrationService,
    private Api: ApiService,
    private UI: UIService,
    public layoutService: LayoutService,
    zone: NgZone,
    private http: HttpClient,
    private appSettings: AppSettingsService,
    private insights: InsightsService,
    private bookingSvc: BookingService
    ) {
    window['back'] = () => {
      zone.run(() => {
        this.prepareBack();
      });
    };

    // Monitor navigation event to keep update step up to date
    this.router.events.pipe(filter(x => x instanceof NavigationStart))
      .subscribe((x: NavigationStart) => {
        if (x.navigationTrigger == 'popstate') {
          this.prepareBack();
        }
      });
  }

  public async init():Promise<void> {
    if (await this.appSettings.getSettings()) {
      this.routes = await this.setRoutes();
      this.router.resetConfig(this.routes);
      this.history = [];
    }

    if (!this._activeStep) {
      var path = this.router.url.substring(1);
      await this.setInitialNavigation(path);
    }
  }

  public async setInitialNavigation(path: string): Promise<void> {
    if (this.getSavedRoute()) {
      await this.navigateToSavedRoute();
      return;
    }
    let step: NavigationStep = this.setStep(path);
    if (path && step && step.Path != "" && step.AllowColdStart) {
      this.setActiveStep(step);
      await this.checkIfloggedInUserInitalNavigation(path);
      await this.executeInitialNavigation();
    }
    else {
      const userId: string = this.appSettings.getUserId();
      if (userId) {
        step = await this.serUserDefaultRoute();
      }
      else {
        step = NavigationService.Steps.find(x => x.Path == "");
      }
      if (this.activeStep) this.history.push(this.activeStep);
      await this.setActiveStep(step);
      await this.executeInitialNavigation();
    }
  }

  public async requiresLogin(key: string): Promise<boolean> {
    var step = NavigationService.Steps.find(x => x.Key == key);
    if (step && step.Context) {
      return step.Context === ApplicationContext.Customer;
    }
    else return false;
  }

  public async checkIfloggedInUserInitalNavigation(path: string) {
    await this.appSettings.waitForAuthentication();
    if ((path == "" || path.indexOf("?") == 0) && this.appSettings.getUserId()) {
      //var token = this.UI.beginLoading("Intellitrailer.App.Navigation.Loading", "Preparing view", null);

      //if logged in go to my page
      await this.executeCommand("MyPage");
    }
    //this.UI.loaderCompleted(token);
  }

  public isFirstStep(): boolean {
    return this.history.length < 2;
  }

  public async getActiveStep() {
    var len = this.router.url.length;
    if (this.router.url.indexOf(";") > 0) len = this.router.url.indexOf(";");

    this.setActiveStep(NavigationService.Steps.find(x => x.Path == this.router.url.substring(1, len)));
  }

  public async executeCommand(command: string, params: any = {}, isGoingBack: boolean = false) {
    if (this.activeStep.ConfirmOnLeave && (this.activeStep.ConfirmOnLeave.DisplayOn.find(x => x as NavType == NavType.NormalNavigation as NavType) > -1)) {
      var confirm = await this.UI.confirm(this.activeStep.ConfirmOnLeave.TitleKey, this.activeStep.ConfirmOnLeave.TitleText, this.activeStep.ConfirmOnLeave.ContentKey, this.activeStep.ConfirmOnLeave.ContentText);
      if (!confirm) return;
    }
    this.layoutService.clear();
    this.isGoingBack = isGoingBack;

    if (this.router.url.indexOf(";") > 0) {
      await this.getActiveStep();
    }

    //Save insights
    this.insights.logEvent('Command: ' + command, { Params: JSON.stringify(params) });

    var action = this.activeStep.Actions.find(x => x.Command == command);
    if (action) {
      var nextStep = NavigationService.Steps.find(x => x.Key == action.TargetStep);
      if (nextStep) {

        if (this.activeStep.Path === nextStep.Path) {
          this.router.navigateByUrl('/Reset/ResetView', { skipLocationChange: true });
        }

        if(!this.isGoingBack) this.history.push(this.activeStep);
        this.setActiveStep(nextStep);

        // Load parameters from declaration
        if (!params) params = {};
        if (this.activeStep.Params) {
          // Update all params that has not been specifically set by the command
          for (var i = 0; i < Object.keys(this.activeStep.Params).length; i++) {
            var key = Object.keys(this.activeStep.Params)[i];
            if (params[key] == undefined) params[key] = this.activeStep.Params[key];
          }
        }
        if (!params || Object.keys(params).length <= 0) params = null;

        if (!params)
          this.router.navigate([this.activeStep.Path]);
        else
          this.router.navigate([this.activeStep.Path, params]);
      }
    }
  }

  //Navigation from the menu
  //Menu navigation allows routing to any view
  public async menuNavigate(command: string, path:string,  params: any = {}) {
    if (this.activeStep.ConfirmOnLeave && (this.activeStep.ConfirmOnLeave.DisplayOn.find(x => x as NavType == NavType.MenuNavigation as NavType) > -1)) {
      var confirm = await this.UI.confirm(this.activeStep.ConfirmOnLeave.TitleKey, this.activeStep.ConfirmOnLeave.TitleText, this.activeStep.ConfirmOnLeave.ContentKey, this.activeStep.ConfirmOnLeave.ContentText);
      if (!confirm) return;
    }
    this.layoutService.clear();
    this.isGoingBack = false;

      const paramsToString = params ? ' Params: ' + JSON.stringify(params) : "";
      this.insights.logEvent('Command: ' + command + 'Path: ' + path + ' Params: '+  paramsToString);

    var nextStep = NavigationService.Steps.find(x => x.Key == command);
    if (nextStep) {
      this.history.push(this.activeStep);
      await this.setActiveStep(nextStep);

      var route = this.activeStep.Path;

      if (command == 'Start') {
        this.router.navigateByUrl('/Menu/MenuNavigation', { skipLocationChange: true }).then(() =>
          this.router.navigate([route]));
      }
      else {
        this.router.navigateByUrl('/Menu/MenuNavigation', { skipLocationChange: true }).then(() =>
          this.router.navigate([route, params]));
      }

      this.layoutService.clear();
    }
  }

  //Reload current route
  public async reload(params: any = {}) {
    var route = this.activeStep.Path;
    this.router.navigateByUrl('/Reset/ReloadView', { skipLocationChange: true }).then(() =>
      this.router.navigate([route, params]));
  }

  public getParam(param: string): string {

    //Angular query paremeter structure
    if (this.router.url.indexOf(";") > -1) {
      var params: string = this.router.url.substring(this.router.url.indexOf(";") + 1, this.router.url.length + 1);
      var paramlist: string[] = params.split(";");
      for (let p of paramlist) {
        if (p.indexOf(param + "=") !== -1) {
          //Parameter found, returns value
          var paramValue = p.split("=")[1];
          if (paramValue && paramValue.length > 0) {
            return paramValue;
          }
        }
      }
    }

    //Normal query parameter structure
    if (this.router.url.indexOf("?") > -1) {
      var params: string = this.router.url.substring(this.router.url.indexOf("?") + 1, this.router.url.length + 1);
      var paramlist: string[] = params.split("&");
      for (let p of paramlist) {
        if (p.indexOf(param + "=") !== -1) {
          //Parameter found, returns value
          var paramValue = p.split("=")[1];
          if (paramValue && paramValue.length > 0) {
            return paramValue;
          }
        }
      }
    }

    // Static parameter defined on route?
    if (this.activeStep.Params && this.activeStep.Params[param]) {
      return this.activeStep.Params[param];
    }

    //Parameter not found
    return null;
  }

  public getParams(): string[] {
    var params: string = this.router.url.substring(this.router.url.indexOf(";") + 1, this.router.url.length + 1);
    var paramlist: string[] = params.split(";");
    return paramlist;
  }

  public async prepareBack() {
   if (this.activeStep.ConfirmOnLeave && this.activeStep.ConfirmOnLeave.DisplayOn.some(x => x as NavType == NavType.BackNavigation as NavType)) {
      var confirm = await this.UI.confirm(this.activeStep.ConfirmOnLeave.TitleKey, this.activeStep.ConfirmOnLeave.TitleText, this.activeStep.ConfirmOnLeave.ContentKey, this.activeStep.ConfirmOnLeave.ContentText);
      if (!confirm) return;
    }
    // Does active step have a defined back action?
    var backCommand = this.activeStep.Actions.find(x => x.Command == "Back");
    if (backCommand) {
      if (backCommand.TargetStep != "Stay") {
        var pop = this.history.pop();
        this.history.push(pop);
        var next = NavigationService.Steps.find(x => x.Key == backCommand.TargetStep);
        while (pop && next && pop.Path != next.Path)
        {
          pop = this.history.pop(); 

        }

        await this.executeCommand("Back", null, true);
      }

      //User going back 
      this.isGoingBack = true;
      return;
    }

    // Otherwise use browser back
    if (this.history.length > 0) {
      await this.setActiveStep(this.history.pop());
      var route = this.activeStep.Path;
      this.router.navigate([route]);
      return;
    }

    //If initial (no history length), cold start is allowed and no defined back route go to Homepage
    if (this.history.length == 0) {
      this.history.pop();

      var oldStep = this.activeStep.Path;

      this.setActiveStep(NavigationService.Steps.find(x => x.Path == ""));
      var route = this.activeStep.Path;
      this.router.navigate([route]);

      if(this.activeStep.Path != oldStep) 
        this.layoutService.clear();
    }

    //User going back 
    this.isGoingBack = true;
  }

  public async back() {
    await this.prepareBack();
  }

  public async getVersion(): Promise<[string, boolean]> {
    return await this.appSettings.getClientVersion();
  }

  public async update() {
    this.insights.logEvent("Reloading");
    var id = uuid.v4();
    window.location.href = this.appSettings.originalHref + "&update=" + id;
  }

  //Save current route before restarting app
  //The route needs to have allowColdStart = true
  public saveRoute(route: string = null) {
    if (!route) {
      route = location.href.split(location.host)[1];
      route = route.substring(1)
    }
    localStorage.setItem("savedRoute", route);
  }

  //Get saved route from localStorage (used when restarting the app)
  public getSavedRoute(): string {
    return localStorage.getItem("savedRoute");
  }

  public clearSavedRoute() {
    localStorage.removeItem("savedRoute");
  }

  private async executeInitialNavigation(): Promise<void> {
    let params = this.unpackQueryParameters(this.activeStep.Params);

    if (params["rentalId"]) {
      await this.bookingSvc.restartSession(parseInt(params["rentalId"]));
    }

    console.log('Running initial navigation');
    if (params && Object.keys(params).length > 0 && this.activeStep.Path != "") {
      this.router.navigate([this.activeStep.Path, params]);
    }
    else {
      this.router.navigate([this.activeStep.Path]);
    }
    this.layoutService.clear();
  }

  private unpackQueryParameters(params: { [Key: string]: string }): { [Key: string]: string } {
    var ret = { ...params };

    if (document.location.search) {
      document.location.search.substring(1)
        .split('&')
        .map(x => ret[x.substring(0, x.indexOf('='))] = x.substring(x.indexOf('=') + 1)
        );
    }

    if (document.location.href.indexOf(";") >= 0) {
      document.location.href.substring(document.location.href.indexOf(";") + 1)
        .split('&')
        .map(x => ret[x.substring(0, x.indexOf('='))] = x.substring(x.indexOf('=') + 1)
        );
    }

    return ret;
  }

  private async navigateToSavedRoute() {
    const savedPath = this.getSavedRoute();
    this.clearSavedRoute();
    let len = savedPath.indexOf(";");
    let savedPathStep = NavigationService.Steps.find(x => x.Path == savedPath.substring(0, len));
    if (savedPathStep) {
      let formatedParams: any = null;
      if (savedPath.indexOf(";") > 0) {
        formatedParams = {}
        const params: string[] = savedPath.substring(savedPath.indexOf(";") + 1, (savedPath.length)).split(";");
        for (let p of params) {
          var paramValue = p.split("=");
          if (paramValue && paramValue.length > 1) {
            formatedParams[paramValue[0]] = paramValue[1];
          }
        }
      }
      this.setActiveStep(savedPathStep);
      await this.appSettings.waitForAuthentication();

      if (savedPathStep && savedPathStep.AllowColdStart) {
        this.menuNavigate(savedPathStep.Key, 'B', formatedParams);
        return;
      }
    }
  }

  private setStep(path: string): NavigationStep {
    if (path.indexOf(";") > 0) {
      var len = path.indexOf(";");
      return NavigationService.Steps.find(x => x.Path == path.substring(0, len));
    }
    else if (path.indexOf("?") > -1) {
      var len = path.indexOf("?");
      return NavigationService.Steps.find(x => x.Path == path.substring(0, len));
    }
    else {
      return NavigationService.Steps.find(x => x.Path == path);
    }
  }
  private async serUserDefaultRoute(): Promise<NavigationStep> {
    const activeOrFutureBookings: RentalInfo[] = await this.Api.get<RentalInfo[]>("Rental/GetMyActiveAndFutureBookings");
    const upcomingBookings = activeOrFutureBookings?.filter(rental => moment.duration(rental.PickupTime.diff(moment.now())).hours() <= 2);
    const activeBookings = activeOrFutureBookings?.filter(rental => rental.Status == 100 || rental.Status == 150);
    if (activeBookings && activeBookings.length === 1) {
      let tmp = NavigationService.Steps.find(x => x.Path == "Rental/InFlight");
      tmp.Params = { rentalId: upcomingBookings[0].Id };
      return tmp;
    }
    else if (upcomingBookings && upcomingBookings.length === 1) {
      let tmp = NavigationService.Steps.find(x => x.Path == "Rental/BookingInfo");
      tmp.Params = { rentalId: upcomingBookings[0].Id };
      return tmp;
    }
    else if (activeOrFutureBookings && activeOrFutureBookings.length > 0) {
      return NavigationService.Steps.find(x => x.Path == "MyPage/Overview");
    }
    else {
      return NavigationService.Steps.find(x => x.Path == "");
    }
  }
}
